.btn {
    display: inline-block;
    padding: 8px 75px;
    border-radius: 14px;
    @include fontSize(20);
    transition: all .5s;
    text-align: center;
    text-decoration: none;
    border-color: transparent;
    cursor: pointer;

    &:focus {
        @include outline($blue);
    }

    &.btn--full-width {
        width: 100%;
    }

    &.btn--gray {
        background-color: $gray;
        color: $white;

        &:hover {
            background-color: $black;
        }
    }

    &.btn--black {
        background-color: $black;
        color: $white;

        &:hover {
            background-color: lighten($black, 20%);
        }
    }

    &.btn--green {
        background-color: $green;
        color: $white;

        &:hover {
            background-color: lighten($green, 20%);
        }
    }

    &.btn--orange {
        background-color: $orange;
        color: $white;

        &:hover {
            background-color: lighten($orange, 20%);
        }
    }

    &.btn--blue {
        background-color: $blue;
        color: $white;

        &:hover {
            background-color: lighten($blue, 20%);
        }
    }

    &.btn--red {
        background-color: $red;
        color: $white;

        &:hover {
            background-color: lighten($red, 20%);
        }
    }
}