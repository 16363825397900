@font-face {
    font-family: 'Avenir';
    src: url('../../assets/fonts/AvenirLTStd-Book.eot');
    src: url('../../assets/fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('../../assets/fonts/AvenirLTStd-Book.woff') format('woff'),
        url('../../assets/fonts/AvenirLTStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}