*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    font-family: $mainFont;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}
