.filter {
    flex-flow: row nowrap;
    margin-bottom: 60px;
    align-content: center;
    @include fontSize(20);

    [class ^= 'col']{
        display: flex;
        flex-flow: row nowrap;
    }

    .filter--title {
        width: max-content;
        flex-shrink: 0;
        margin: 0;
        padding: 0;
        padding-right: 40px;
    }

    .filter--list {
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;
        flex-flow: row nowrap;
        overflow-x: scroll;

        .filter--item {
            list-style: none;
            cursor: pointer;
            flex-shrink: 0;
            color: $blue;
            transition: all .5s;

            &+.filter--item {
                margin-left: 40px;
            }

            &.filter--item-selected {
                color: $green;
            }
        }
    }
}