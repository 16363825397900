.searchbar {
    flex-flow: row wrap;
    height: 60px;
    margin-bottom: 60px;
    align-content: center;

    [class^="col-"] {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &:first-child {
            margin-bottom: 15px;

            @media(min-width: $md) {
                margin-bottom: 0;
            }
        }
    }

    .searchbar--form {
        position: relative;
        width: 100%;

        button {
            position: absolute;
            margin: 0;
            padding: 0;
            width: 15px;
            height: 15px;
            top: calc(50% - (15px / 2));
            left: 10px;
            background: transparent;
            border: none;
            
            &::after {
                content: '\e900';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                line-height: 15px;
                font-family: $icomoon;
                font-size: 15px;
                text-align: center;
                cursor: pointer;
            }

            @media(min-width: $md) {
                width: 30px;
                height: 30px;
                top: 15px;
                left: 20px;

                &::after {
                    width: 30px;
                    line-height: 30px;
                    font-size: 30px;
                }
            }
        }

        input {
            &[type="text"] {
                width: 100%;
                height: 60px;
                padding-left: 30px;
                @include fontSize(15);
                border: 2px solid $black;
                border-radius: 15px;
                line-height: 60px;
                vertical-align: middle;
                outline: none;
                transition: all 0.5s;

                &:focus {
                    border-color: $blue;
                }

                @media(min-width: $md) {
                    padding-left: 80px;
                    @include fontSize(30);
                }
            }
        }
    }
}
