.title {
    width: 100%;

    &.title--white {
        color: $white;
    }

    &.title--black {
        color: $black;
    }

    .title--green {
        color: $green;
    }

    .title--blue {
        color: $blue;
    }

    &.title--large {
        @include fontSize(60);

        @media(max-width: $md) {
            @include fontSize(40);
        }
    }

    &.title--medium {
        @include fontSize(40);

        @media(max-width: $md) {
            @include fontSize(30);
        }
    }

    &.title--small {
        @include fontSize(30);

        @media(max-width: $md){
            @include fontSize(20);
        }
    }
}