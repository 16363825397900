.card {
    display: block;
    width: 100%;
    padding: 20px;
    border-radius: 14px;

    .card-title {
        margin: 0;
        @include fontSize(60);
        font-weight: 200;

        @media (max-width: $sm) {
            @include fontSize(40);
        }

        &~.card-sub-title {
            margin-top: 23px;
        }
    }

    .card-sub-title {
        margin: 0;
        @include fontSize(30);

        @media (max-width: $sm) {
            @include fontSize(15);
        }
    }

    &.card--link {
        cursor: pointer;
        text-decoration: none;
    }

    &.card--black {
        background-color: $black;
        color: $white;
    }

    &.card--blue {
        background-color: $blue;
        color: $white;
    }

    &.card--orange {
        background-color: $orange;
        color: $white;
    }

    &.card--gray {
        background-color: $gray;
        color: $white;
    }

    &.card--green {
        background-color: $green;
        color: $white;
    }
}

.card-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 398px);
    justify-content: center;
    column-gap: 16px;
    row-gap: 20px;

    @media (max-width: $sm) {
        grid-template-columns: 100%;
        
    }
}