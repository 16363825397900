.list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    .list-item {
        width: 100%;

        a {
            text-decoration: none;
        }

        .list-item--body {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 20px 30px;
            flex-flow: row nowrap;
            align-items: center;
            color: $white;
            background: $black;
            border-radius: 14px;

            @media (max-width: $md) {
                flex-flow: row wrap;
            }

            .list-item--small-col {
                width: max-content;
                max-width: 10%;
                flex-shrink: 0;

                @media (max-width: $md) {
                    width: 100%;
                    max-width: auto;
                }

                &+.list-item--large-col {
                    margin-left: 50px;

                    @media (max-width: $md) {
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
            }

            .list-item--large-col {
                width: 100%;
            }
        }

        .list-item--date {
            margin: 0;
            padding: 0;
            @include fontSize(20);

            @media (max-width: $lg) {
                @include fontSize(14);
            }
        }

        .list-item--data {
            margin: 0;
            padding: 0;
            @include fontSize(30);

            @media (max-width: $lg) {
                @include fontSize(20);
            }
        }

        &+.list-item {
            margin-top: 20px;
        }
    }
}