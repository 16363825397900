.text {
    width: 100%;
    @include fontSize(20);

    @media(max-width: $md){
        @include fontSize(14);
    }

    &.text--link {
        cursor: pointer;
    }

    &.text--white {
        color: $white;
    }

    &.text--black {
        color: $black;
    }

    &.text--green {
        color: $green;
    }

    &.text--blue {
        color: $blue;
    }

    &.text--line-breaker {
        white-space: pre-line;
    }
}