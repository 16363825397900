.page-login {
    height: 100vh;

    .page-background{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .page-content {
        display: flex;
        height: 100%;
        width: 35%;
        min-width: 692px;
        padding: 20px;
        padding-left: 20px;
        flex-flow: row wrap;
        align-content: center;
        justify-content: center;
        background: $white;

        @media (max-width: $md) {
            padding-left: 20px;
            width: 50%;
            min-width: auto;
        }

        @media (max-width: $sm) {
            width: 100%;
        }

        .form {
            max-width: 400px;
        }
    }

    .logo {
        max-width: 140px;
        width: 100%;
    }
}