@font-face {
    font-family: 'icomoon';
    src:  url('../../assets/fonts/icomoon.eot?b72t7f');
    src:  url('../../assets/fonts/icomoon.eot?b72t7f#iefix') format('embedded-opentype'),
      url('../../assets/fonts/icomoon.ttf?b72t7f') format('truetype'),
      url('../../assets/fonts/icomoon.woff?b72t7f') format('woff'),
      url('../../assets/fonts/icomoon.svg?b72t7f#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-icon-search:before {
    content: "\e900";
  }
  .icon-icon-logout:before {
    content: "\e901";
  }
  