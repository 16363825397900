.navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    background: $white;
    @include fontSize(20);
    box-shadow: 0px 10px 10px -4px rgba($black,0.2);
    z-index: 9999;

    .container {
        height: 100%;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: start;
    }

    .navbar--start {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        @media (min-width: $md) {
            width: max-content;
        }
    }

    .navbar--end {
        position: fixed;
        display: flex;
        flex-flow: column wrap;
        top: 100px;
        left: 100%;
        width: 100vw;
        height: calc(100vh - 100px);
        padding: 20px;
        background: $white;
        transition: all .5s;

        &.navbar--open {
            left: 0;
        }

        @media (min-width: $md) {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            flex-flow: row nowrap;

        }
    }

    .navbar--items {
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0 0 20px 0;
        flex-flow: column wrap;
        border-bottom: 1px solid rgba($black, .1);

        @media (min-width: $md) {
            padding: 0 0 0 50px;
            flex-flow: row nowrap;
            align-items: center;
            border-bottom: none;
        }
    }

    .navbar--item {
        list-style: none;
        
        a {
            color: $black;
            text-decoration: none;
            cursor: pointer;
            transition: all .5s;

            &:hover {
                color: $blue;
            }
        }

        &+.navbar--item {
            margin-top: 10px;

            @media (min-width: $md) {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }

    .navbar--tools {
        display: flex;
        padding: 10px 0 0 0;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        @media (min-width: $md) {
            flex-shrink: 0;
            padding: 0;
        }
    }

    .navbar--user {
        width: max-content;
        margin: 0;
        padding: 0;

        &+.navbar--button {
            margin-left: 15px;
        }
    }

    .navbar--button {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        &.navbar--hamburger{
            display: flex;
            width: 40px;
            height: 30px;
            margin: 0 0 0 20px;
            padding: 0;
            flex-flow: row wrap;
            align-content: space-between;

            .navbar--hamburger-line {
                width: 100%;
                height: 5px;
                background: $black;
                border-radius: 20px;
                transition: all .5s;
            }

            &.navbar--hamburger-cross {
                .navbar--hamburger-line {
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(9px, 9px);
                    }
                    &:nth-child(2) {
                        width: 0%;
                        transform: translate(20px, 0);
                    }
                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(9px, -9px);
                    }
                }
            }

            @media (min-width: $md) {
                display: none;
            }
        }
    }
}