.form {
    width: 100%;

    .form-row {
        display: flex;
        width: 100%;
        margin: 0;
        flex-flow: row wrap;

        &+.form-row {
            margin-top: 40px;
        }

        .col-md-6 {
            @media (max-width: $md) {
                &+.col-md-6 {
                    margin-top: 40px;
                }
            }
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="date"],
    input[type="email"],
    textarea,
    select {
        width: 100%;
        margin: 0;
        padding: 10px 20px;
        border: $black solid 1px;
        border-radius: 14px;
        transition: all .5s;
        color: $black;
        @include fontSize(20);


        &:focus {
            border-color: $blue;
            @include outline($blue);
        }
    }

    textarea {
        min-height: 230px;
        @include fontSize(20);
        resize: none;
    }

    label {
        display: block;
        width: 100%;
        margin: 0 0 10px;
        color: $black;
        @include fontSize(20);

        &.form--error {
            margin-top: 10px;
            color: $red;
        }
    }

    input[type=submit] {
        width: 100%;
    }
}